// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__bxa--block', {
    slidesPerView: 4,
    spaceBetween: 15,
    rewind: true,
    navigation: {
        nextEl: ".section__bxa--nav-next",
        prevEl: ".section__bxa--nav-prev",
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        },
    },
})